import React, { useState } from 'react';
import jsonp from 'jsonp';
import queryString from 'query-string';
import './BlogSignUp.scss';

function BlogSignUp() {
  const [stateEmail, setEmail] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  // const handleAddSubscriber = async (email) => {
  //   setIsClicked(true);
  //   try {
  //     const response = await addSubscriber(email);
  //     const subscriber = response.data;
  //   } catch (err) {
  //     const errorMessage = err.data;
  //     console.log(errorMessage);
  //   }
  // };

  const subscribeToNewsLetter = (email, event) => {
    // prevent page reload
    event.preventDefault();

    const formData = {
      EMAIL: email,
    };

    jsonp(`https://ortom.us6.list-manage.com/subscribe/post-json?u=e0a03e76204b2ee9149fc7636&amp;id=7ecb50801c&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      // console.log('err:', err);
      // console.log('data:', data);
      // overwrite email with success or error message

      if (data.msg.includes('is already subscribed to list Ortom.')) {
        setEmail(email + ' is already subscribed to list');
      } else {
        // catch all for all errors
        setEmail(data.msg);
      }
    });
  };

  return (
    <div className="blogSignUp">
      <div>
        <br />
        <form autoComplete="on" onSubmit={(e) => subscribeToNewsLetter(stateEmail, e)}>
          <div className="field has-addons has-addons-centered is-fullwidth black-underline">
            <p className="control" style={{ width: '100%' }}>
              <input className="input input-simple-inverse" type="email" name="email" placeholder="Your email" onChange={(event) => setEmail(event.target.value)} value={stateEmail} />
            </p>
            <button type="submit" className={isClicked ? 'button input-arrow-blog' : 'button input-arrow-blog'}>
              →
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BlogSignUp;
